import { render, staticRenderFns } from "./OrderToPay.vue?vue&type=template&id=c455d27e&scoped=true&"
var script = {}
import style0 from "./OrderToPay.vue?vue&type=style&index=0&id=c455d27e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c455d27e",
  null
  
)

export default component.exports